.table-section {
  margin-top: calc(var(--header-height) + 30px);
  animation: fadeIn 0.3s;
}

.table-section__btn {
  margin-bottom: 25px;
  background: transparent;
  transition: var(--standart-transition);
}

.table-section__btn span {
  color: var(--page-font-color);
  font-size: 17px;
}

.table-section__btn svg {
  border: 1px solid var(--page-font-color);
  border-radius: 50%;
  padding: 4px;
  width: 10px;
}

.table-section__btn svg line {
  stroke: var(--page-font-color);
  stroke-width: 10px;
}

.table-section__btn:hover {
  background: #c2c2c2;
}



.table {
  border-spacing: 0;
  border-radius: 7px;
  text-align: center;

  width: 95vw;
  max-width: 900px;
}

.table__row td {
  height: 28px;
  border-bottom: 1px solid var(--header-font-color);
}

.table__row td:first-of-type {
  text-align: start;
  padding-left: 10px;
  width: 35%;
  transition: var(--standart-transition);
}

.table__row td:first-of-type:hover {
  cursor: pointer;
  opacity: 0.7;
}

.table__head-row > th {
  padding-bottom: 5px;
  font-weight: normal;
  color: #868686;
  position: relative;
  transition: var(--standart-transition);
  min-width: 130px;
  border-bottom: 1px solid var(--header-font-color);
}

/* .table__head-row > th:hover {
  cursor: pointer;
  color: #fff;
} */

/* .table__head-row > th:hover[data-title="date"] {
  cursor: default;
  color: #868686;
} */

.table__row:nth-of-type(odd) {

}

.table__sort-logo {
  width: 21px;
  margin-left: 3px;
  position: absolute;
  bottom: 5px;
  opacity: 0;
  transition: all 0.3s;
}

.table__sort-logo_active {
  opacity: 0.6;
}

.table__status {
  background: #c2c2c2;
  border-radius: 10px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  border: none;
  outline: none;
  width: 105px;
  box-sizing: border-box;
  transition: var(--standart-transition);
}

.table__progress {
  width: 105px;
  height: 21px;
  background: #cecece;
  border-radius: 10px;
  margin: auto;
  position: relative;
}

.table__progress_filled {
  width: 50%;
  height: 100%;
  background: rgb(41 184 36);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.table__progress span {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.95em;
}

.table__link {
  display: block;
}

@media (max-width: 580px) {
  .table {
    font-size: 14px;
  }

  .table__head-row > th {
    min-width: 85px;
  }

  .table__sort-logo {
    display: none;
  }

  .table__row td:last-of-type {
    display: none;
  }

  .table__head-row > th:last-of-type {
    display: none;
  }

  .table__status {
    width: 95px;
  }

}
