.comment-form {
  display: flex;
  flex-direction: column;
}

.comment-form__text {
  min-height: 40px;
}

.comment-form__btn-container {
  display: flex;
}

.comment-form__btn {
  border-radius: 20px;
  margin-top: 5px;
  color: var(--task-text-color);
  width: 105px;
  height: 25px;
  border: none;
  background-color: rgb(60 60 60);
  transition: var(--standart-transition);
  font-size: 15px;
}

.comment-form__btn_active {
  color: var(--task-main-font-color);
  cursor: pointer;
}

.comment-form__btn_active:hover {
  opacity: 0.7;
}

.comment-form__btn-cancel {
  margin-left: 5px;
  background: none;
  transition: var(--standart-transition);
  display: none;
  width: 90px;
}

.comment-form__btn-cancel:hover {
  opacity: 0.7;
}

.comment-form__btn-cancel_active {
  display: block;
  cursor: pointer;
}




