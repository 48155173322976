.task-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 50px;
}

.task-list__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 97%;
  max-width: 700px;

}

.task-list__container {
  display: flex;
  align-items: center;
  width: 70%;
  cursor: pointer;
  z-index: 1;
}

.task-list__title {
  font-weight: normal;
  font-size: 15px;
}

.task-list__title_complete {
  color: rgb(1 1 1 / 50%);
}

.task-list__btn {
  height: 18px;
  width: 18px;
  padding: 0;
  border-radius: 10px;
  border: 1px solid;
  background: transparent;
  margin-right: 7px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.task-list__complete-logo {
  width: 70%;
  opacity: 0;
  transition: var(--standart-transition);
}

.task-list__complete-logo_active {
  opacity: 1;
}

.task-list__complete-logo:hover {
  opacity: 1;
}

.task-list__btn:hover {

}

.task__term {
  background: #c2c2c26b;
  width: auto;
}

.task-list__tools {
  display: flex;
  gap: 7px;
}

.task-list__tools img {
  width: 22px;
  cursor: pointer;
  transition: var(--standart-transition);
  opacity: 0.5;
}

.task-list__tools img:hover {
  opacity: 1;
}

.dnd {
  display: flex;
  align-items: baseline;
  margin-top: 70px;
  flex-wrap: wrap;
  justify-content: center;
  animation: fadeIn 0.3s;
}

.dnd__container {
  padding: 10px 0 10px;
  width: 280px;
  min-height: 120px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dnd__column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px;
}

.dnd__column > h2 {
  margin: 0 0 10px 0;
  font-size: 20px;
  font-weight: normal;
}

.dnd__task {
  user-select: none;
  margin: 0 0 8px 0;
  color: rgb(36, 36, 36);
  min-height: 50px;
  width: 85%;
  border-radius: 7px;
  padding: 5px;
  box-shadow: 0px 0px 3px 1px #7e7e7e70;
  background-color: #ffffff5e;
  display: flex;
  justify-content: center;
}

.dnd__btn {
  background: transparent;
  margin: 0 0 10px 0;
}

.dnd__btn:hover {
  opacity: 0.5;
  background-color: transparent;
}

.dnd__btn svg line {
  stroke-width: 10px;
  stroke: #535252;
}

.dnd__btn span {
  color: #535252;
  display: block;
}

@media (max-width: 780px) {
  .task-list__title {
    font-size: 14px;
  }

  /* .popup__term {
    font-size: 13px;
    width: 95px;
  }

  .popup__status {
    font-size: 13px;
    width: 100px;
  } */

  /* .task-list__tools img {
    width: 20px;
  } */
}

@media (max-width: 580px) {
  .popup__status-list {
    display: none;
  }

  .task-list__tools img {
    display: none;
  }

  .task-list__container {
    width: 75%;
    margin-right: 7px;
  }

}
