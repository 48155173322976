.popup {
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  margin: 0;
  z-index: 1;
  transition: background-color 0.3s;
  animation: fadeIn 0.3s;
}

.popup_active {
  display: flex;
  background-color: rgba(1, 1, 1, 0.8);
  overflow: auto;
}

.popup__form {
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  background: #1e1e1ec2;
  max-width: 700px;
  min-height: 100%;
  width: 90vw;
  border-radius: 15px;
  box-sizing: border-box;
}

.popup__form_active {
  visibility: visible;
}

.popup__btn {
  width: 115px;
  height: 30px;
  border-radius: 20px;
  border: none;
  background: #c2c2c2;
  color: var(--task-text-color);
  transition: var(--standart-transition);
  font-size: 15px;
}

.popup__btn:first-of-type {
  margin-right: 5px;
  color: #000;
}

.popup__btn:hover {
  cursor: pointer;
  opacity: 0.7;
}

.popup__input {
  border: none;
  border-bottom: 1px solid var(--task-background);;
  padding-bottom: 5px;
  margin-top: 7px;
  background-color: #00000000;
  font-size: 17px;
  color: var(--task-main-font-color);
  outline: none;
}

.popup__label {
  display: flex;
  flex-direction: column;
  color: var(--task-text-color);;
  min-width: 80%;
  font-size: 16px;
  position: relative;
}

.popup__container {
  display: flex;
  margin: 35px 0 0 55px;
  align-items: baseline;
  flex-direction: column;
}

.popup__img {
  width: 16px;
  height: 16px;
  position: absolute;
  left: -25px;
  top: -1px;
}

.popup__img_big {
  width: 20px;
  height: 20px;
  top: -5px;
}

.popup__textarea {
  background: var(--task-background);
  border: none;
  border-radius: 5px;
  resize: none;
  color: var(--task-main-font-color);
  min-height: 50px;
  padding: 4px;
  outline: none;
  font-size: 15px;
}

.popup__label_text {
  min-width: 80%;
}

.popup__info-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 35px;
}

.popup__input-upload {
  display: none;
}

.popup__label-upload {
  padding: 6px;
  display: flex;
  align-self: baseline;
  background: var(--task-background);
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  opacity: 1;
  transition: var(--standart-transition);
}

.popup__file-name {
  position: absolute;
  left: 145px;
  top: 31px;
}

.popup__label-upload:hover {
  opacity: 0.7;
}

.btn-cross {
  border: none;
  display: flex;
  align-items: center;
  padding: 3px 5px;
  background: var(--task-background);
  border-radius: 5px;
  margin-left: 10px;
  transition: var(--standart-transition);
}

.btn-cross:hover {
  background: var(--task-background);
  cursor: pointer;
}

.btn-cross svg {
  display: block;
  transform: rotate(45deg);
  width: 8px;
}

.btn-cross svg line {
  stroke: var(--task-text-color);
  stroke-width: 5;
}

.btn-cross span {
  color: #8B8B8B;
  font-size: 14px;
  margin-left: 5px;
}

.popup__term {
  background: #c2c2c2;
  border-radius: 7px;
  padding: 3px;
  font-size: 13px;
  border: none;
  outline: none;
  box-sizing: border-box;
  color: #535252;
  transition: var(--standart-transition);
  width: 100px;
}

.popup__close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 8px;
  border-radius: 50%;
  background: none;
  transition: var(--standart-transition);
  transform: rotate(45deg);
}

.popup__close-btn svg {
  width: 18px;
}

.popup__btn-container {
  display: flex;
  justify-content: center;
  position: fixed;
  min-height: 55px;
  bottom: 5px;
  width: 90%;
  max-width: 700px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.popup__term:hover {
  opacity: 0.8;
}

.popup__status {
  background: #c2c2c2;
  border-radius: 7px;
  padding: 3px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  width: 115px;
  height: 23px;
  box-sizing: border-box;
  transition: var(--standart-transition);
}

.popup__status:hover {
  cursor: pointer;
  opacity: 0.8;
}

.popup__status option {
  background: #fff;
}

.popup__status_pending {
  color: #665b1a;
  background: #e0cf64;
}

.popup__status_complete {
  color: #0d3b14;
  background: #4ab853;
}

.popup__priority {
  width: 90px;
}

.popup__time {
  color: var(--task-text-color);
  font-size: 14px;
}

.popup__label-heading {
  margin-bottom: 30px;
}

.popup__element {
  margin-top: 12px;
}

.popup__subtask-btn {
  margin: 0;
  transition: var(--standart-transition);
  padding: 6px;
}

.popup__subtask-btn:hover {
  background: #323232;
}

.popup__subtask-btn svg {
  transform: rotate(45deg);
  width: 10px;
}

.popup__subtask-btn  span {
  color: var(--task-text-color);
  font-size: 14px;
  margin-left: 5px;
}

.popup__subtask-btn svg line {
  stroke-width: 11px;
}

.popup__subtask-container {
  background: var(--task-background);
  border-radius: 5px;
  padding: 10px;
  transition: var(--standart-transition);
  max-height: 220px;
  box-sizing: border-box;
  overflow: auto;
}

.popup__subtask-container::-webkit-scrollbar {
  width: 8px;
  background-color: #c2c2c2;
  border-radius: 5px;
}

.popup__subtask-container::-webkit-scrollbar-thumb  {
  background-color: #1e1e1eba;
  border-radius: 5px;
}


@media (max-width: 580px) {
  .popup__container {
    margin: 25px 0 0 0;
  }

  .popup__close-btn {
    top: 17px;
    right: 23px;
  }

  .popup__label {
    min-width: 95%;
  }

  .popup__form_active {
    width: 100%;
    padding: 15px;
    border-radius: 0;
  }

  .popup__img {
    display: none;
  }

  .popup__btn-container {
    left: 0;
  }
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}




