
:root {
  --page-background: rgb(231 229 229);
  --header-font-color: #bdc1c6;
  --page-font-color: #343434;
  --standart-transition: all 0.3s linear;
  --content-width: 90%;
  --header-height: 50px;
  --task-text-color: #8B8B8B;
  --task-background: #3c3c3c;
  --task-main-font-color: #e4e4e4;
  --link-color: #4685de;
}

.page {
  min-height: 100vh;
  background-color: var(--page-background);
  font-family: 'Century Gothic', 'Montserrat', Arial, Helvetica, Sans-serif;
  color: var(--page-font-color);
  display: flex;
  justify-content: center;
}

.page__container {

}

.body_unscrolled{
  overflow: hidden;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


