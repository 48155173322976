.comment-list {
  margin-top: 25px;
  margin-bottom: 60px;
}

.reply-list {
  margin: 20px 0 0 25px;
  display: none;
}

.reply-list_active {
  margin: 20px 0 0 25px;
  display: block;
}
