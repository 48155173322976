.comment {
  color: var(--task-main-font-color);
  margin-bottom: 20px;
}

.comment:last-of-type {
  margin-bottom: 0;
}

.comment__data {
  display: flex;
  align-items: center;
}

.comment__data-container {
  margin-left: 7px;
}

.comment__user-name {
  margin: 0 0 3px;
}

.comment__date {
  margin: 0;
}

.comment__avatar {
  width: 30px;
  height: 30px;
  background: #c2c2c2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
}

.comment__btn {
  background: none;
  border: none;
  color: inherit;
  padding: 0;
  cursor: pointer;
  transition: var(--standart-transition);
}

.comment__secondary-data {
  font-size: 13px;
  color: var(--task-text-color);
}

.comment__btn:hover {
  opacity: 0.7;
}

.comment__btn-view {
  color: var(--link-color);
  margin-left: 15px;
  display: none;
}

.comment__btn-view_active {
  display: initial;
}

.comment__text {
  margin: 10px 0 10px;
  font-size: 15px;
}
