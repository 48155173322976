
.subtask-form  {
  display: none;
  opacity: 0;
  animation: fadeIn 0.3s;
}

.subtask-form_active {
  display: block;
  opacity: 1;
}

.subtask-input {
  background: #c2c2c2;
  margin: 5px 0 8px;
  border: none;
  min-width: 240px;
  border-radius: 5px;
  min-height: 25px;
}

.subtask-btn {
  background: #4a4a4a;
  width: 91px;
  font-size: 13px;
  height: 25px;
}

.subtask-btn:first-of-type {
  color: #dadada;
}
